import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import assignroles1 from 'img/help/assignroles1.png';
import assignroles2 from 'img/help/assignroles2.png';
import editroles1 from 'img/help/editroles1.png';
import editroles2 from 'img/help/editroles2.png';

const Content = () => {
  return (
    <div>
      <p>
        You can <strong>assign different</strong> user roles in LiveSession. Thanks to this, you
        have more control over your team’s access to the app. You can choose from three different
        roles:
      </p>
      <p>
        <strong>Owner:</strong>
      </p>
      <ul>
        <li>has full access to all features</li>
        <li>can add other users and edit user roles</li>
        <li>can’t be removed from the account</li>
      </ul>
      <p>
        <strong>Admin:</strong>
      </p>
      <ul>
        <li>has full access to all features</li>
        <li>can add other users and edit user roles</li>
      </ul>
      <p>
        <strong>User:</strong>
      </p>
      <ul>
        <li>can’t add or remove websites</li>
        <li>can’t access Invoices and Plan & Billing</li>
        <li>can only see basic plan info and the number of sessions left for the account</li>
      </ul>
      <p>
        Here’s how to <strong>assign user roles</strong> to your teammates:
      </p>
      <ol>
        <li>
          Go to the Team tab and click on the Invite Teammate button in the top right corner. You’ll
          see a pop-up window:
          <Image src={assignroles1} alt="Invite teammate" title="Send invitation" />
        </li>
        <li>
          Enter the teammate’s email and choose their role, either User or Admin:
          <Image
            src={assignroles2}
            alt="Choose role and fill an email address"
            title="Send invitation"
          />
        </li>
        <li>Click on the “Send Invitation” button.</li>
      </ol>
      <p>
        Here’s how to <strong>edit user roles:</strong>
      </p>
      <ol>
        <li>
          Go to the Team tab. You’ll see a list of all the users:
          <Image src={editroles1} alt="Edit roles" title="Team tab - list of users" />
        </li>
        <li>
          Find the user you’d like to edit and select a role from the drop-down menu:{' '}
          <Image
            src={editroles2}
            alt="Edit roles - select a role"
            title="Team tab - list of roles"
          />
        </li>
        <li>All done! The role is now updated.</li>
      </ol>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'How to assign and edit user roles?',
  metaDescription: 'You can assign different user roles in LiveSession',
  canonical: '/help/how-to-assign-and-edit-user-roles/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
